

// import * as $ from 'jquery';


/**
 *  @HelperFunctions to give helper functions
 *  @author       Arne Winter
 *  @requires
 */


 // Custom Passwörter generieren
var HelperFunctions = (function() {
    var DateConverter = {
      getLocalDateString: function(datestring)
      {
        const newDate = new Date(datestring).toLocaleDateString;
        return newDate;
      },

      fancyTimeFormat(time)
      {   
          // Hours, minutes and seconds
          var hrs = ~~(time / 3600);
          var mins = ~~((time % 3600) / 60);
          var secs = ~~time % 60;

          // Output like "1:01" or "4:03:59" or "123:03:59"
          var ret = "";

          if (hrs > 0) {
              ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
          }

          let minText = ``;
          mins > 0.0 ? minText = mins+ " Min ": null;
          ret += "" + minText + (secs < 10 ? "" : "");
          ret += "" + secs+ " Sek";
          return ret;
      }

    };
  
    var PasswordGenerator = {
        generate: function()
        {
          // https://www.geekstrick.com/random-password-generator-with-angularjs/
          let password = "";
          let password_length=6;
            let miscWordArray = ['Pando' , 'Pandi', 'Panda', 'Pandemonium', 'Giro', 'Matrix' ,'Haus', 'Tisch', 'Platz' , 'CO', 'Gulp', 'Saft', 
            'Sombrero', 'Holi', 'Electro', 'Benzin', 'Schaumstoff', 'Untrue', 'MetalRat', 'HokusPoku', 'Sichtfeld', 'Angola', 'Kenia', 'Tai', 'Wei', 'Rost', 'Kartoffel',
          'Hirsch', 'Cro', 'Fos', 'Platz', 'Rucola', 'Spitz', 'Rast', 'Schiff', 'Zugang', 'Rondo', 'Fuerte', 'Griech']
            let upperCaseArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 
            'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 
            'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            let lowerCaseArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
            'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 
            's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

            let numbersArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            let symbolsArray = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', 
            '_', '-', '+', '=', '{', '}', '[', ']', '|', '\\', ':',
            ';', '"', '\'', '<', '>', ',', '.', '?', '/', '`', '~'];
            // let temp = Math.floor(Math.random()*upperCaseArray.length);
            // password += upperCaseArray[temp];
            // temp = Math.floor(Math.random()* lowerCaseArray.length);
            // password += lowerCaseArray[temp];
            // temp = Math.floor(Math.random()*numbersArray.length);
            // password += numbersArray[temp];

            // let upperLowerArray = upperCaseArray.concat(lowerCaseArray); 
            // temp = Math.floor(Math.random()*upperLowerArray.length);
            // password += upperLowerArray[temp]; 

             /* if upperCase : true, & lowerCase : true, & numbers   : true, */
             for (var i=0; i<password_length; i++) 
             {               
              let upperLowerArray = upperCaseArray.concat(lowerCaseArray); 
              let upperLowerNumbersArray = upperLowerArray.concat(numbersArray); 
              let allArray = upperLowerNumbersArray.concat(miscWordArray);
              let temp = Math.floor(Math.random()*upperLowerNumbersArray.length);
              password += upperLowerNumbersArray[temp]; 
             }
             let index = Math.floor(Math.random()*miscWordArray.length);
             password = miscWordArray[index]+password; 
            return password;
        }
    };


    let Sorting = {
      filterAbstimmungen: function(array_ , use_abstimmung_greater = -2)
      {
        return array_.filter(a => {return a.abstimmung > use_abstimmung_greater});
      },
      greaterSort: function(a, b)
      {
        if ( a.rang < b.rang ){
          return -1;
        }
        if ( a.rang > b.rang ){
          return 1;
        }
        return 0;
      },
     abstimmungsManagerForRow: function(top_row, versammlung_id, alleAbstimmungen)
      {
        // if(top_row.abstimmung_erforderlich === 1 && top_row.hasOwnProperty("einheiten") && top_row.einheiten.length > 0)
        if(top_row.hasOwnProperty("einheiten") && top_row.einheiten.length > 0)
        {
          top_row.abstimmungen = {gesammelt:[], summary: {abgestimmt:0, abstimmung_notwendig: 0}};
          for(let e = 0; e < top_row.einheiten.length; e++ )
          {
            top_row.abstimmungen.summary.abstimmung_notwendig++;
            let eheit = top_row.einheiten[e];
            let filteredAbstimmungen = alleAbstimmungen.filter(abst => {return (abst.einheit_id === eheit.einheit_id && abst.versammlung_id === versammlung_id && abst.tagesordnungspunkt_id === top_row.tagesordnungspunkt_id)});
            // eheit.abstimmung = filteredAbstimmungen;   
            if(filteredAbstimmungen.length > 0)
            {
              filteredAbstimmungen = filteredAbstimmungen[0];
              top_row.abstimmungen.gesammelt.push(filteredAbstimmungen );
              eheit.abstimmung = filteredAbstimmungen;
              eheit.abstimmung_gesammelt_index = top_row.abstimmungen.gesammelt.length-1;
              if(filteredAbstimmungen.hasOwnProperty("abstimmung") && filteredAbstimmungen.abstimmung != -2)
              {
                top_row.abstimmungen.summary.abgestimmt++;
              }
            }           
          }
        }
        return top_row;
      }

    };

    let localStorageHelper = {
      set: function(data)
      {
        for(let i = 0; i < data.length; i++)
        {
          localStorage.setItem(data[i].key, data[i].value);
        }
      },
      get: function(data)
      {
        let liste = [];
        try {
          for(let i = 0; i < data.length; i++)
          {
            if(localStorage.getItem(data[i]))
            {
              let loc_s = localStorage.getItem(data[i]);
              if(loc_s)
              {
                let row = {};
                row[data[i]] = loc_s;
                liste.push(row);
              }
            }          
          }
        } catch (error) {
        }
        return liste;
      },
      remove: function(data)
      {
        try {
          for(let i = 0; i < data.length; i++)
          {
            localStorage.removeItem(data[i])
          }
        } catch (error) {
        }
    
      }
    }

    var Sha1 = {
      get: function(obj)
      {
        // return require('sha1').sha1(obj);
        return function(){ return sha1.sha1(obj); }
      },
      getHashedNumberArray: function(count)
      {
          try {
              let hashedArray = [];
              for (let index = 0; index < count; index++) {
                  hashedArray.push(sha1(index));
              }
              return hashedArray;
          } catch (error) {
          }
      },
      getHashedNumberDictionary: function(count)
      {
          try {
              let hashedArray = [];
              for (let index = 0; index < count; index++) {
                  hashedArray.push({number: index, hash: sha1(index)});
              }
              return hashedArray;
          } catch (error) {
          }
      }
    };
    var Misc = {
        testvar: null,
        castBoolean: function(str )
        {
          return str === "false"? false : str === "true" ? true: str;
        },
        isJson: function(obj) 
        {
          var t = typeof obj;
          return ['boolean', 'number', 'string', 'symbol', 'function'].indexOf(t) == -1;
        },
        isJson2: function(object)
        {
          var firstShot = typeof object;
          if (firstShot !== 'object') {
              return firstShot;
          } 
          else if (object.constructor === [].constructor) {
              return 'array';
          }
          else if (object.constructor === {}.constructor) {
              return 'object';
          }
          else if (object === null) {
              return 'null';
          }
          else {
              return 'don\'t know';
          } 
        },
        sortByRang: function(a, b)
        {
          if ( a.rang < b.rang ){
            return -1;
          }
          if ( a.rang > b.rang ){
            return 1;
          }
          return 0;
        },
        sortByUnterpunkte: function(c, d)
        {
          if ( c.ist_unterpunkt < d.ist_unterpunkt )
          {
            return -1;
          }
          if ( c.ist_unterpunkt > d.ist_unterpunkt )
          {
            return 1;
          }
          return 0;
        }
    }
  
    return {
      DateConverter: DateConverter,
      PasswordGenerator: PasswordGenerator,
      Misc: Misc,
      Sorting: Sorting,
      Sha1: Sha1,
      localStorageHelper
    }
  })()
  